export default [
  {
    key: "search",
    label: "field.title",
    type: "text",
  },
  {
    key: "year",
    label: "field.year",
    type: "text",
  },
  // {
  //   key: "isMain",
  //   label: "field.main",
  //   rules: "required",
  //   type: "radio",
  //   cast: "boolean",
  //   options: [
  //     { text: "field.main", value: "1" },
  //     { text: "field.notMain", value: "0" },
  //   ],
  //   lg: "auto",
  // },
  {
    key: "isEnable",
    label: "field.status",
    rules: "required",
    type: "radio",
    cast: "boolean",
    options: [
      { text: "field.active", value: "1" },
      { text: "field.inactive", value: "0" },
    ],
    lg: "auto",
  },
];
